<template>
  <div class="personal-profile-container">
    <div class="personal-profile-body bgc_fff">
      <header class="personal-profile-header flex flex_jcbetween flex_acenter">
        <span class="fontsize_16 color_26">发票历史</span>
      </header>
      <div class="personal-profile-main">
        <div
          class="personal-profile-explain fontsize_14 color_8C lineheight_25"
        >
          <p
            class="
              flex flex_acenter
              fontsize_14
              color_26
              lineheight_20
              margin_bot3
            "
          >
            <i class="icon-upgrade-remind fontsize_12 margin_right4"></i>
            发票须知
          </p>
          <p class="flex">
            开票时间：无特殊或不可抗力情况下，我司将在<span class="color_FF0031"
              >7个工作日内</span
            >为您开票，并发送到您填写的邮箱;
          </p>
          <p class="flex">
            开票类型：<span class="color_FF0031">增值税电子普通发票</span
            >。如有特殊开票要求，可联系在线客服;
          </p>
          <p class="flex">服务名称：软件服务费或技术服务费;</p>
          <p class="flex">
            开票税率：我司开出的发票金额均为含税价格，<span class="color_FF0031"
              >税率 13%</span
            >；
          </p>
          <p class="flex fontsize_12">
            注意：由于税务开票数量限制，如急需开具发票，可联系在线客服加急处理。若当月开票量太大，发票限额用尽，可能会延迟为您开票，望知悉。
          </p>
        </div>
        <div v-if="total > 0">
          <div class="table-box">
            <el-table
              :data="lists"
              style="width: 100%"
              height="auto"
            >
              <el-table-column prop="apply_time" label="申请时间" width="173">
              </el-table-column>
              <el-table-column prop="invoice_type" label="发票类型" width="173">
                <template slot-scope="scope">
                  <span v-if="scope.row.invoice_type == 1"
                    >增值税电子普通发票</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="order_nos" label="订单编号" width="360">
              </el-table-column>
              <el-table-column
                prop="total_price"
                label="开票金额（￥）"
                width="173"
              >
              </el-table-column>
              <el-table-column prop="state" label="状态" width="173">
                <template slot-scope="scope">
                  <span v-if="scope.row.state == 0" class="color_FF7B00"
                    >待开票</span
                  >
                  <span v-if="scope.row.state == 1" class="color_26"
                    >已开票</span
                  >
                  <span v-if="scope.row.state == 2" class="color_FF0031"
                    >已驳回</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作">
                <template slot-scope="scope">
                  <span class="span" @click="openApplyDialog(scope.row, 1)"
                    >申请信息</span
                  >
                  <span class="span" @click="openApplyDialog(scope.row, 2)"
                  v-if="scope.row.state == 2"
                    >驳回信息</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flex flex_end padding_top16 padding_bottom16">
            <customPage
              @change="handlePageChange"
              :total="total"
              :size="limit"
            ></customPage>
          </div>
        </div>
        <div class="custom__nodata" style="min-height: 510px" v-if="total == 0">
          <div
            class="
              fontsize_14
              lineheight_20
              flex
              felx_acenter
              flex_center
              flexcolumn
            "
          >
            <img src="@/assets/nodata/order_nodata.svg" height="156px" />
            <span class="margin_top16 color_8C flex felx_acenter flex_center"
              >您暂无历史发票订单</span
            >
            <span
              class="
                margin_top13
                color_E74362
                flex
                felx_acenter
                flex_center
                pointer
                hoverColor_B5102F
              "
              @click="openUpgradeDialog"
              >去升级</span
            >
          </div>
        </div>
      </div>
    </div>
    <fd-custom-dialog
      :visible.sync="applyDialogVisible"
      title="申请信息"
      custom-class="apply-dialog"
      width="800px"
    >
      <div class="apply-dialog__content">
        <div class="head">
          <p class="fontsize_14 color_26 lineheight_28">
            订单号：{{detail.order_nos}}
          </p>
          <p class="fontsize_14 color_26 lineheight_28">
            开票金额：<strong>{{detail.total_price}}</strong>元
          </p>
        </div>
        <p class="margin_top10 margin_bot10 fontsize_16 color_26">申请信息</p>
        <div class="lineheight_22 fontsize_14 color_8C">
          <p class="margin_bot10">
            公司名称：<span class="color_26">{{ detail.company_name }}</span>
          </p>
          <p class="margin_bot10">
            公司税号：<span class="color_26">{{ detail.tax_number }}</span>
          </p>
          <p class="margin_bot10">
            公司注册电话：<span class="color_26">{{ detail.reg_number }}</span>
          </p>
          <p class="margin_bot10">
            开户银行：<span class="color_26">{{ detail.bank_name }}</span>
          </p>
          <p class="margin_bot10">
            公司银行账号：<span class="color_26">{{
              detail.bank_account
            }}</span>
          </p>
          <p class="margin_bot10">
            发票接收邮箱：<span class="color_26">{{ detail.email }}</span>
          </p>
          <p class="margin_bot10">
            备注：<span class="color_26">{{ detail.remark }}</span>
          </p>
          <p class="margin_bot10 flex margin_top24" v-if="detail.state === 1">
            <span class="flex1"
              >申请时间：<span class="color_26">{{
                detail.created_at
              }}</span></span
            >
            <span class="flex1"
              >状态：
              <span class="color_26" v-if="detail.state === 0">待开票</span>
              <span class="color_FF0031" v-if="detail.state === 1">已开票</span>
              <span class="color_FF7B00" v-if="detail.state === 2">已驳回</span>
            </span
            >
          </p>
          <div class="margin_bot10" v-if="detail.state === 2 && detail.reason">
            <div class="margin_bot10">驳回信息：</div>
            <p class="info color_26">
             {{detail.reason}}
            </p>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex flex_jcbetween flex_acenter">
        <div class="fontsize_12 color_8C">
          <p
            class="
              flex flex_acenter
              fontsize_14
              color_26
              lineheight_20
              margin_bot3
            "
          >
            <i class="icon-upgrade-remind fontsize_12 margin_right4"></i>
            发票须知
          </p>
          <p class="flex lineheight_25">
            开票时间：无特殊或不可抗力情况下，我司将在<span class="color_FF0031"
              >7个工作日内</span
            >为您开票，并发送到您填写的邮箱。
          </p>
          <p class="flex lineheight_25">
            开票类型：我司开票类型为<span class="color_FF0031"
              >增值税电子普通发票</span
            >。如有特殊开票要求，可联系在线客服。
          </p>
        </div>
        <el-button
          class="custom__button"
          @click="openInvoiceDialog"
          v-if="detail.state == 2 && applyType==2"
          >重新申请开票</el-button
        >
      </div>
    </fd-custom-dialog>
    <fd-upgrade-dialog :show.sync="upgradeDialogVisible"></fd-upgrade-dialog>
    <!-- 申请开票 -->
    <invoice-dialog
      :visible.sync="invoiceDialogVisible"
      title="申请开票"
      :form="form"
      @submit="submitApply"
    ></invoice-dialog>
  </div>
</template>

<script>
import { getToken } from "@/libs/utils/auth";
import customButton from "@/renderer/components/common/button.vue";
import customPage from "@/renderer/components/common/customPage.vue";
import invoiceDialog from "@/renderer/components/common/invoiceDialog.vue";
import { mapActions } from "vuex";
import {
  getInvoiceList,
  getInvoiceDetail,
  invoiceApply,
} from "@/libs/api/upgrade";
export default {
  components: { customButton, customPage, invoiceDialog },
  data() {
    return {
      token: null,
      page: 1,
      applyDialogVisible: false,
      invoiceDialogVisible: false,
      applyType: 1,
      lists: [],
      limit: 10,
      total: -1,
      upgradeDialogVisible: false,
      invoice_id: "",
      detail: {},
      form: {
        order_no: [],
        totalPrice: 0.0,
        company_name: "",
        tax_number: "",
        reg_number: "",
        bank_name: "",
        bank_account: "",
        remark: "",
        email: "",
      },
    };
  },
  computed: {},
  async created() {
    // const token = await getToken();
    // this.token = token;
    this.getLists();
  },
  methods: {
    ...mapActions(["GetUserDetail", "UpdateUserInfo"]),
    // getInfo() {
    //   this.GetUserDetail().then(res => {
    //     if (res.errorcode == 0) {
    //       this.form = res.data;
    //     }
    //   }).catch();
    // },
    getLists() {
      getInvoiceList({ page: this.page, limit: this.limit })
        .then((res) => {
          if (res.errorcode == 0) {
            this.lists = res.data;
            this.total = res.total;
          }
        })
        .catch((err) => {});
    },
    getDetail() {
      getInvoiceDetail({ invoice_id: this.invoice_id })
        .then((res) => {
          if (res.errorcode == 0) {
            this.detail = res.data;
            this.applyDialogVisible = true;
          }
        })
        .catch((err) => {});
    },
    handlePageChange(page) {
      this.page = page;
      this.getLists();
    },
    openApplyDialog(item, type) {
      this.invoice_id = item.id;
      this.applyType = type;
      this.getDetail();
    },
    openInvoiceDialog() {
      this.form = this.detail;
      this.invoiceDialogVisible = true;
    },
    openUpgradeDialog() {
      // this.currentBox=1;
      this.upgradeDialogVisible = true;
    },
    // 提交申请开票
    submitApply(obj) {
      if (obj.order_nos) {
        let order_on = [];
        obj.order_nos.split(',').forEach(item => {
          order_on.push(item)
        });
        obj.order_no=order_on;
      }
      invoiceApply(obj).then((res) => {
        if (res.errorcode == 0) {
          this.$message.success("开票成功");
          this.invoiceDialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@E74362: #e74362;
@ce3b59: #ce3b59;
@8C8C8C: #8c8c8c;
@D9D9D9: #d9d9d9;
@595959: #595959;
@FFFFFF: #ffffff;

.personal-profile-container {
  box-sizing: border-box;
  padding: 16px 0;
  height: 100%;
}

.personal-profile-body {
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 4px;
  // padding: 0 24px;
  width: 89.4%;
  height: 100%;
  max-width: 1288px;
  max-height: 800px;
}

.personal-profile-header {
  height: 54px;
  padding: 0 32px;
  border-bottom: 1px solid #f0f0f0;
}

.personal-profile-main {
  height: calc(100% - 54px);
  overflow: hidden auto;
  box-sizing: border-box;
  padding: 16px 32px;
}

.personal-profile-explain {
  background: #f8eff1;
  border-radius: 4px;
  padding: 16px;
}
.table-box {
  margin-top: 16px;
  border-right: 1px solid #f7e7ea;
  border-left: 1px solid #f7e7ea;
  border-radius: 4px;
}
/deep/.el-table {
  &::before {
    background-color: #f7e7ea;
  }
  & th.el-table__cell {
    background: #f8eff1;
    padding: 17px 0;
    .cell {
      color: #262626;
      font-weight: 400;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  & td.el-table__cell {
    padding: 17px 0;
    .cell {
      color: #262626;
      padding-right: 16px;
      padding-left: 16px;
      .span {
        color: #e74362;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          color: #b5102f;
        }
      }
    }
  }
  & td.el-table__cell,
  & th.el-table__cell.is-leaf {
    border-bottom: 1px solid #f7e7ea;
  }
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: rgba(247, 231, 234, 0.2);
}
/deep/.apply-dialog {
  box-shadow: none;

  .el-dialog {
    &__header {
      padding: 16px;
      border-bottom: 1px solid #ededed;
    }

    &__title {
      font-size: 16px;
      color: #262626;
    }

    &__body {
      padding: 0;
    }

    &__footer {
      padding: 8px 32px;
      border-top: 1px solid #f0f0f0;
    }
  }

  .el-form-item {
    margin-bottom: 16px;

    &__content {
      line-height: 32px;
    }

    &__label {
      color: #262626;
      line-height: 32px;
    }

    &__error {
      padding-top: 1px;
    }

    .el-input {
      height: 32px;
      line-height: 32px;
    }
    .el-input__inner {
      border-radius: 2px;
    }
    .el-textarea__inner {
      border-radius: 2px;
    }
  }
}

.apply-dialog {
  &__content {
    padding: 0 32px;

    .head {
      padding: 9px 16px;
      background: #f8eff1;
      border-radius: 4px;
      margin-top: 16px;
    }
    .info {
      border-radius: 4px;
      padding: 9px 16px;
      background: #f8eff1;
    }
  }
}

.custom__button {
  min-width: 88px;
  min-height: 30px;
  line-height: 20px;
  background: @E74362;
  border: 1px solid @E74362;
  border-radius: 4px;
  font-size: 14px;
  color: @FFFFFF;
  padding: 5px 16px;

  &.other {
    min-width: 80px;
    min-height: 32px;
    color: @E74362;
    background: @FFFFFF;
    border: 1px solid @E74362;

    &:hover {
      color: @FFFFFF;
      background: @E74362;
      border: 1px solid @E74362;
    }
  }

  &.dark {
    color: @FFFFFF;
    background: @E74362;
    border: 1px solid @E74362;

    &:hover {
      color: @FFFFFF;
      background: @ce3b59;
      border: 1px solid @ce3b59;
    }
  }

  &.gray {
    color: @8C8C8C;
    background: @FFFFFF;
    border: 1px solid @D9D9D9;

    &:hover {
      color: @595959;
      background: @FFFFFF;
      border: 1px solid @D9D9D9;
    }
  }
  &.darkgray {
    min-width: 80px;
    min-height: 32px;
    color: #bfbfbf;
    background: #f5f5f5;
    border: 1px solid @D9D9D9;

    &:hover {
      color: #bfbfbf;
      background: #f5f5f5;
      border: 1px solid @D9D9D9;
    }
  }

  &:hover {
    background: @ce3b59;
    border: 1px solid @ce3b59;
  }

  &.full {
    width: 100%;
  }
}
.custom__nodata {
  min-height: 578px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @FFFFFF;
}
</style>